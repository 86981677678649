#solutions {
  background: url(../assets/images/solutions-bg.png) no-repeat;
  background-size: 100% 100%;
  height: 90vh;
  /* display: flex; */
  align-items: center;
}

#solutions .info-text-content {
  max-width: 655px;
}

#solutions .info-container-right {
  position: relative;
  top: -56px;
}

@media only screen and (max-height: 700px) {
  #solutions {
    height: 35rem;
  }
}

@media only screen and (max-width: 1023px) {
  #solutions {
    height: 35rem;
  }
}

@media only screen and (max-width: 767px) {
  #solutions {
    height: 33rem;
  }
}

@media only screen and (max-width: 500px) {
  #solutions {
    background: url(../assets/images/solutions-bg-mobile.png) no-repeat;
    background-size: cover;
  }
}