.logo {
  width: 16rem;
  /* height: 3rem; */
}
.text-top {
  display: flex;
  flex-direction: column;
  gap: 2rem;
}
.top {
  &__bg {
    background: url(../assets/images/top-bg.png) no-repeat;
    width: 100%;
    position: relative;
    top: 100%;
    height: 93vh;
    background-size: 105% 105%;
    background-position: -57px;
  }

  &__title {
    width: 80%;
    color: #fff;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }

  &__subtitle {
    width: 53rem;
    color: #fff;
    font-style: normal;
    font-weight: 300;
    line-height: normal;
    font-size: 1.5rem;
  }

  &__text {
    display: flex;
    flex-direction: column;
    padding: 25px;
    gap: 4rem;
    height: 90%;
    justify-content: center;
  }

  &__button {
    cursor: pointer;
    max-width: 35rem;
    width: 100%;
    height: 5rem;
    color: #fff;
    font-size: 2rem;

    font-weight: bold;
    display: flex;
    align-items: center;
    justify-content: center;

    background: #816701;
    border-radius: 1.5rem;
    border: 0 solid;
    outline: 1px solid;

    outline-color: #816701;
    outline-offset: 0px;
    text-shadow: none;
    -webkit-transition: all 1250ms cubic-bezier(0.19, 1, 0.22, 1);
    transition: all 1250ms cubic-bezier(0.19, 1, 0.22, 1);

    &__position {
      display: flex;
      position: relative;
      justify-content: center;
      bottom: 2.6rem;
      z-index: 4;
    }
  }
}

.top__button:hover {
  outline-color: #e6bc18;
  outline-offset: 25px;
  background-color: #e6bc18;
  outline-color: rgba(255, 255, 255, 0);
}


@media only screen and (max-height: 700px) {
  .logo {
    margin: 1rem 0rem;
    width: 22rem;
    height: 5rem;
  }

  .top {
    &__button {
      &__position {
        bottom: 6.12rem;
      }
    }

    &__bg {
      height: 52rem;
      background-repeat: no-repeat;
      gap: 5px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      background-size: cover;
    }

    &__title {
      width: 80%;
      color: #fff;
      font-style: normal;
      line-height: normal;
      /* font-size: 3rem; */
      text-align: left;
    }

    &__subtitle {
      width: 54rem;
      // font-size: 1rem;
    }

    &__text {
      margin-left: 0;
    }
  }
}

@media only screen and (max-width: 1400px) {
  .top {
    &__button {
      font-size: 1.5rem;
      border-radius: 1rem;
      width: 28rem;
      height: 3.5rem;

      &__position {
        display: flex;
        justify-content: center;
        bottom: 2rem;
      }
    }
  }

  .logo {
    width: 14rem;
    height: 3rem;
    margin: 0 0rem 0rem 4rem;
  }

  .top__title {
    width: 50%;
    color: #fff;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    font-size: 1.8rem;
    margin-left: 5rem;
  }
  
  .top__subtitle {
    width: 50%;
    color: #fff;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    text-align: left;
    margin-left: 5rem;
    font-size: 1rem;
  }

  .top__bg {
    width: 100%;
    height: 90vh;
    background-repeat: no-repeat;
    gap: 5px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .text-top {
    gap: 1rem;
    align-items: flex-start;
  }

  .info__images {
    width: 3rem !important;
    height: 3rem !important;
  }
}

@media only screen and (max-width: 1023px) {
  .logo {
    margin: 1rem 3rem;
    width: 14rem;
    height: 2rem;
  }
  .top {
    &__bg {
      height: 55rem;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      background-size: cover;
      background-position: 0;
    }
      
    &__title {
      width: 90%;
      font-size: 1.75rem;
      text-align: center;
      margin-left: 0;
    }

    &__subtitle {
      width: 90%;
      font-size: .925rem;
      text-align: center;
      margin: 0 auto;
    }

    &__text {
      margin-left: 0;
      gap: 3rem;
    }

    &__button {
      width: 23rem;
      height: 3.5rem;
      border-radius: 0.5rem;
    }
  }

  .text-top {
    width: 100%;
    gap: 2rem;
    align-items: center;
  }
}

@media only screen and (max-width: 767px) {
  .logo {
    margin: 0;
    margin-bottom: 3rem;
    width: 12rem;
    height: 3rem;
  }
  .top {
    &__bg {
      height: 40rem;
    }

    &__title {
      width: 100%;
      font-size: 1.325rem;
    }

    &__subtitle {
      font-size: 0.8rem;
    }

    &__text {
      gap: 0px;
    }

    &__button {
      width: 14rem;
      height: 2.5rem;
      border-radius: 0.5rem;
      color: #fff;
      font-size: 0.9rem;

      &__position {
        display: flex;
        position: relative;
        justify-content: center;
        bottom: 1.3rem;
      }
    }
  }
}
