#main-technologies {
  height: fit-content;
  padding-top: 90px;
  padding-bottom: 80px;
  background: linear-gradient(269.97deg, rgba(0, 0, 0, 0) -0.63%, 
  rgba(0, 0, 0, 0.00978723) 14.79%, 
  rgba(0, 0, 0, 0.0193037) 24.54%, 
  rgba(0, 0, 0, 0.46) 61.37%, 
  rgba(0, 0, 0, 0.46) 70.99%, 
  rgba(0, 0, 0, 0.46) 76.81%, 
  rgba(0, 0, 0, 0.46) 88.19%), #002447;
}

.colmeia-trigger {
  display: block;
  width: 100%;
  position: absolute;
  top: 50%;
  height: 2px;
}

#main-technologies .info-text {
  color: white;
  margin-bottom: 50px;
  text-align: center;
}

.comeia {
  display: flex;
  width: 100%;
  position: relative;
  flex-direction: column;
  align-items: center;
  font-size: 7px;
  line-height: 0px;
}

.colmeia-container:first-of-type {
  position: relative;
  top: 37px;
}

.colmeia-container:last-of-type {
  position: relative;
  top: -37px;
}

.colmeia-container > * {
  display: inline-block;
}

.colmeia-item {
  opacity: 0;
  text-align: center;
  /*   width: fit-content;
  height: fit-content; */
  box-sizing: border-box;
  width: 178px;
  height: 193px;
  position: relative;
  transform: scale(1.044);
  transition: 0.175s;
  margin: 0;

  &__text {
    color: #fff;
    font-size: 1.6rem;
    position: absolute;
    text-align: center;
    top: 53%;
    left: 50%;
    font-weight: 700;
    transform: translate(-50%, -50%);
  }

  &:hover {
    transform: scale(1.1);
    z-index: 1000;
  }
}

.image-comeia {
  width: 100%;
}

.comeia > * {
  display: inline;
}

@media only screen and (max-width: 1260px) {
  .colmeia-item {
    width: 148px;
    height: 171px;
    &__text {
      font-size: 1.4rem;
    }
  }
}

@media only screen and (max-width: 1160px) {
  .colmeia-item {
    width: 138px;
    height: 162px;

    &__text {
      top: 50%;
    }
  }
}

@media only screen and (max-width: 1023px) {
  .colmeia-container {
    display: flex;
    flex-direction: column-reverse;
    gap: 5px;
  }

  .colmeia-container:first-of-type {
    top: 0px;
    left: -5px;
  }

  .colmeia-container:last-of-type {
    top: 0px;
    left: 5px;
  }

  .colmeia-item {
    transform: scale(1.17);
    font-size: 1px;
    height: 152px;

    &:hover {
      transform: scale(1.2);
    }
  }

  .comeia {
    flex-direction: row;
    justify-content: center;
  }

  .image-comeia {
    transform: rotate(90deg);
  }
}

@media only screen and (max-width: 500px) {
  .colmeia-container {
    display: flex;
    flex-direction: column-reverse;
    gap: 0px;
  }

  .colmeia-container:first-of-type {
    top: 0px;
    left: -5px;
  }

  .colmeia-container:last-of-type {
    top: 0px;
    left: 5px;
  }

  .colmeia-item {
    transform: scale(1.19);
    font-size: 0px;
    width: 89px;
    height: 103px;

    &__text {
      font-size: 0.9rem;
    }

    &:hover {
      transform: scale(1.2);
    }
  }
}

/* .image-comeia {
 transform: scale(1.044);
 transition: .2s;
}

.image-comeia:hover {
  transform: scale(1.1);
  z-index: 1000;
 }
  */
