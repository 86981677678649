#factory-products {
  height: fit-content;
  padding: 50px 20px;
}

#factory-products .info-text {
  color: #fff;
  font-size: 2.25rem;
  margin-bottom: 70px;
  text-align: center;
}

.transparent-card-container {
  gap: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.transparent-card {
  border: 5px solid #fff;
  border-radius: 65px;
  width: 330px;
  height: 330px;
  padding: 25px;
  color: #fff;
  margin-top: 12px;
  margin-bottom: 12px;
  transition: .4s;

  &__icon {
    display: block;
    margin: 0 auto;
    width: 75px;
    height: 75px;
  }

  &__title {
    font-size: 1.3rem;
    margin-top: 10px;
    margin-bottom: 10px;
    height: 45px;
    text-align: center;
    text-transform: uppercase;
  }

  &__text {
    font-weight: 700;
    font-size: 1.1rem;
  }

  &:hover {
    border-color: #d9ad00;
    background-color: #004283;
    transform: scale(1.05);
  }
}

.icon-responsive {
  background: url(../assets/svg/responsive.svg) no-repeat;
  background-size: 100%;
}

.icon-gestao {
  background: url(../assets/svg/gestao.svg) no-repeat;
  background-size: 100%;
}

.icon-integracao {
  background: url(../assets/svg/integracao.svg) no-repeat;
  background-size: 100%;
}

.icon-mobile {
  background: url(../assets/svg/mobile.svg) no-repeat;
  background-size: 100%;
}

.icon-personalizados {
  background: url(../assets/svg/personalizados.svg) no-repeat;
  background-size: 100%;
}

.icon-soa {
  background: url(../assets/svg/soa.svg) no-repeat;
  background-size: 100%;
}

/* @media only screen and (max-height: 700px) {
  #factory-products {
    height: 35rem;
  }
}
 */

@media only screen and (max-width: 1023px) {
  #factory-products .info-text {
    font-size: 1.6rem;
  }

  .transparent-card {
    width: 300px;
    height: 300px;
    padding: 20px;
  
    &__icon {
      display: block;
      margin: 0 auto;
      width: 70px;
      height: 70px;
    }
  
    &__title {
      font-size: 1.2rem;
      margin-top: 10px;
      margin-bottom: 10px;
      height: 45px;
      text-align: center;
      text-transform: uppercase;
    }
  
    &__text {
      font-weight: 700;
      font-size: 1rem;
    }
  }
}

@media only screen and (max-width: 767px) { 
  
  #factory-products .info-text {
    font-size: 1.625rem;
  }

  .transparent-card-container {
    flex-direction: column;
    gap: 0px;
  }

  .transparent-card {
    width: 270px;
    height: 270px;
    padding: 20px;
  
    &__icon {
      display: block;
      margin: 0 auto;
      width: 60px;
      height: 60px;
    }
  
    &__title {
      font-size: 1rem;
      margin-top: 10px;
      margin-bottom: 10px;
      height: 40px;
    }
  
    &__text {
      font-size: .8rem;
    }
  }
}