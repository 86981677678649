.information {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

#mobile {
  position: relative;
}

.information .info-text {
  color: #fff;
  font-size: 2.25rem;
  margin-bottom: 30px;
  text-align: center;
}

.bg-faq {
  display: flex;
  align-items: center;
  min-height: 874px;
  background-color: #fafafa;
}

.faq-title {
  text-align: center;
  padding: 1rem;
  color: #fff;
}

#faq > div {
  width: 100%;
}

.faq-body {
  max-width: 1100px;
  text-align: center;
}

.faq-body > div {
  padding: 2rem;
  height: 100%;
}

.faq-list {
  background-color: #5c7389;
  margin: 12px 0;
  /*   padding: 20px 5px; */
  border-radius: 6px;
}

div.faq-view {
  // display: flex;
  /* display: none; */
  opacity: 0;
  flex-direction: column;
  height: 0px;
  gap: 24px;
  background: #002447db;
  border-radius: 6px;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  transition: 0s;

  & h3 {
    color: #fff;
    text-align: start;
  }
}

.faq-view > p {
  color: #fff;
}

.active .faq-view {
  //  display: flex;
  max-height: max-content;
  opacity: 1;
  width: 100%;
  /* background-color: rgba(0, 0, 0, 0.750); */
  transition: 0.6s;
  height: fit-content;
  padding: 8px 3px;
}

div.faq-item {
  position: relative;
  /* padding-top: 24px; */
  // padding-bottom: 24px;
  padding: 20px 5px;
  text-align: start;
  cursor: pointer;

  & > p {
    width: 100%;
    color: #fff;
    padding: 0 20px;
    text-align: center;
  }

  /* 
  &::after {
    content: "";
    display: block;
    margin-top: 24px;
    width: 100%;
  } */
  /* 
  &:nth-last-child(n + 2)::after {
    height: 2px;
    background-color: #d9d9d9;
  } */

  & > span.arrow {
    display: block;
    width: 20px;
    height: 25px;
    position: absolute;
    top: 33%;
    right: 0;
    margin-left: 30px;
    background-image: url("../assets/svg/arrow-down.svg");
    background-repeat: no-repeat;
    background-position: center;
    background-size: 100% 100%;
    right: 10px;
  }
}

.faq-list.active .faq-item > p {
  font-weight: bold;
  color: #fff;
}

.faq-list.active .faq-item > span.arrow {
  background-image: url("../assets/svg/arrow-top.svg");
  background-size: 100% 100%;
}

.mob-button {
  cursor: pointer;
  max-width: 28rem;
  width: 100%;
  height: 4rem;
  color: #fff;
  font-size: 1.8rem;

  font-weight: bold;
  display: flex;
  align-items: center;
  justify-content: center;

  background: #816701;
  border-radius: 1.5rem;
  border: 0 solid;
  outline: 1px solid;

  outline-color: #816701;
  outline-offset: 0px;
  text-shadow: none;
  -webkit-transition: all 1250ms cubic-bezier(0.19, 1, 0.22, 1);
  transition: all 1250ms cubic-bezier(0.19, 1, 0.22, 1);

  &__position {
    display: flex;
    position: absolute;
    z-index: 4;
    justify-content: center;
    margin: 0 auto;
    left: 0;
    height: fit-content;
    right: 0;
    bottom: -1.6rem; 
  }
}
.mob-button:hover {
  outline-color: #e6bc18;
  outline-offset: 25px;
  background-color: #e6bc18;
  outline-color: rgba(255, 255, 255, 0);
}

#mobile {
  background: url(../assets/images/mobile-bg.png) no-repeat;
  background-size: cover;
  width: 100%;
  height: fit-content;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 30px;

  &__title {
    color: #fff;
    text-align: center;
    padding-top: 60px;
  }

  &__img {
    width: 26rem;
    height: 25rem;
  }

  &__container {
    margin-top: 1rem;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-around;

    & a {
      > img {
        width: 5rem;
        height: 5rem;
      }
    }
  }
  &__btn {
    width: 5rem;
    height: 5rem;
  }
  &__btn.prev {
    background: url(../assets/svg/arow-left.svg) no-repeat;
    background-size: contain;
  }

  &__btn.next {
    background: url(../assets/svg/arow-right.svg) no-repeat;
    background-size: contain;
  }
}

#body {
  background-color: #000;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
}

.swiper {
  padding-bottom: 60px !important;
  width: 100%;
  height: 45rem;
  .swiper-wrapper {
    align-items: center;
  }
  .swiper-slide {
    border-radius: 5px;
    width: 1rem;
    height: 10rem !important;
    transition: 0.2s;
    display: flex;
    justify-content: center;
    &.active {
      height: 18rem !important;
    }
  }
  .prev,
  .next {
    cursor: pointer;
    z-index: 100;
    position: absolute;
    top: 40%;
    transform: translateY(-50%);
    opacity: 0.65;
    transition: 0.2s;
    &:hover {
      opacity: 1;
    }
    svg {
      width: 10rem;
      height: 3rem;
    }
    svg path {
      fill: #fff;
    }
  }
  .prev {
    left: 0;
    width: 28rem;
    height: 100%;
    display: flex;
    align-items: center;
  }
  .next {
    right: 0;
    width: 28rem;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: end;
  }
}

@media only screen and (max-width: 1400px) {
  .mob-button {
    font-size: 1.5rem;
    border-radius: 1rem;
    width: 28rem;
    height: 3.5rem;

    &__position {
      display: flex;
      justify-content: center;
      bottom: -1.6rem;  
    }
  }
}

@media only screen and (max-width: 1023px) {
  .information .info-text {
    font-size: 1.6rem;
  }

  #mobile {
    margin-top: 0;

    &__title {
      font-size: 1rem;
    }
  }
  .swiper {
    height: 300px;
  }
  .swiper .swiper-slide.active {
    height: 16rem !important;
  }
  .swiper .swiper-slide {
    height: 5rem !important;
  }

  .swiper {
    .prev {
      width: 19rem;
    }
    .next {
      width: 19rem;
    }

    .prev,
    .next {
      svg {
        width: 10rem;
        height: 3rem;
      }
    }
  }

  .mobile {
    width: 100%;
    height: 18rem;
    background-repeat: no-repeat;
    margin-top: 40px;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;

    &__title {
      font-size: 17px;
    }

    &__img {
      width: 12rem;
      height: 11rem;
    }

    &__container {
      & a {
        > img {
          width: 2rem;
          height: 2rem;
        }
      }
    }
  }
  .mob-button {
    width: 23rem;
    height: 3.5rem;
    border-radius: 0.5rem;
  }
}

@media only screen and (max-width: 767px) {
  .information .info-text {
    font-size: 1.625rem;
  }

  div.faq-item > p {
    width: 90%;
    color: #fff;
    font-size: 15px;
  }

  .faq-view > p {
    font-size: 12px;
  }

  .mob-button {
    width: 14rem;
    height: 2.5rem;
    font-size: 0.9rem;

    &__position {
      bottom: -1.2rem;
    }
  }

  #mobile {
    &__title {
      font-size: 1rem;
    }
  }
  .swiper .swiper-slide.active {
    height: 10rem !important;
  }
  .swiper {
    .prev {
      width: 10rem;
    }
    .next {
      width: 10rem;
    }
    .prev,
    .next {
      svg {
        width: 5rem;
        height: 3rem;
      }
    }
  }

  .mobile {
    width: 100%;
    height: 18rem;
    background-repeat: no-repeat;
    margin-top: 40px;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;

    &__title {
      font-size: 17px;
    }
  }
}
