#info {
  position: relative;
  background: url("../assets/images/info-bg.png") no-repeat;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  height: 90vh;
  /* background-position: -50rem -5rem, 145%; */
}

.info-img {
  width: 35rem;
}
.info {
  &__cards {
    display: flex;
    align-items: center;
    justify-content: space-around;
    gap: 5rem;
    align-items: center;

    &__container {
      display: flex;
      align-items: center;
      justify-content: space-around;
      gap: 5rem;
      align-items: center;
    }
  }
  &__project {
    color: #fff;
    &__container {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: 2px;

      & h2 {
        font-size: 2rem;
      }
    }
  }
  &__images {
    width: 5rem;
    height: 5rem;
  }
  &__text {
    width: 13rem;
    color: #fff;
    text-align: center;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }
}

.info__images.building {
  margin-left: 2rem;
}

@media only screen and (max-height: 700px) {
  #info {
    height: 35rem;
  }

 /*  .info-container {
    margin-top: 0;
    width: 90%;
  } */

  .info {
    &__images {
      width: 4rem;
      height: 4rem;
    }
    &__project {
      font-size: 17px;
      &__container {
        gap: 5px;
      }
    }
    &__cards {
      gap: 1rem;
    }
    &__text {
      width: 10rem;
      font-size: 9px;
    }
  }
  .info__project__container h2 {
    font-size: 1.5rem;
  }
  .info__text {
    font-size: 0.8rem;
  }
  .info__cards {
    width: 100%;
    margin-top: 1rem;
  }
  .info__cards__container {
    width: 90%;
    display: flex;
    align-items: center;
    justify-content: space-around;
    align-items: center;
  }

  .info-container {
    height: fit-content;
/*     gap: 10px;
    width: 90%; */
  }

  .info__images.building {
    margin-left: 1rem;
  }
}

@media only screen and (max-width: 1400px) {
  .info {
    &__images {
      width: 3rem;
      height: 3rem;
    }
    &__project {
      &__container h2 {
        font-size: 1.5rem;
      }
    }
    &__text {
      font-size: 0.8rem;
    }
  }
}

@media only screen and (max-width: 1200px) {
  .info-img {
    width: 20rem;
  }
}

@media only screen and (max-width: 1023px) {
  .info {
    &__project {
      &__container {
        flex-direction: column;
        h2 {
          font-size: 1.2rem;
        }
      }
    }

    &__images {
      width: 7rem;
      height: 7rem;
    }
    &__cards {
      gap: 1rem;

      &__container {
        flex-direction: column;
      }
    }
  }
  .info__cards {
    width: 100%;
    margin-top: 1rem;
  }

  #info {
    height: 35rem;
  }

  .info-text-content {
    font-size: 1.1rem;
  }
}

@media only screen and (max-width: 767px) {
  #info {
    height: 33rem;
  }

  .info-img {
    width: 14rem;
  }

  .info {
    &__cards {
      &__container {
        width: 90%;
        gap: 1rem;
      }
    }

    &__project {
      &__container {
        h2 {
          font-size: 1rem;
        }
      }
    }

    &__text {
      width: 13rem;
    }
  }
}

@media only screen and (max-width: 500px) {
  #info { 
  background: url(../assets/images/info-bg-mobile.png) no-repeat;
  background-size: cover;
  background-position: 0;  
  }
  

  .info-img {
    width: 10rem;
  }
}
