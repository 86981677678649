@import "top";
@import "info";
@import "factory-products";
@import "solutions";
@import "information";
@import "main-technologies";
@import "mobile";
@import "info-about-us";
@import "contact";
@import "footer";

:root {
  --color-primary: #582597;
  --color-secondary: #B06300;
  --color-gray: #60596b;
  --color-white-bg: #ffffff;
  --color-gray-bg: #e5e5e5;
  --font-white: #fff;
}

* {
  text-decoration: none;
  box-sizing: border-box;
}

body {
  background: #002447;
  font-family: "SF Pro Display", Arial, sans-serif;
  font-style: normal;
  font-size: 1.125rem;
}

h1 {
  font-size: 3.25rem;
  font-weight: 700;
  line-height: 150%;
}

h2 {
  font-size: 2rem;
  font-weight: 700;
  line-height: 150%;
}

h3 {

  font-style: normal;
  font-weight: 600;
  font-size: 1.7rem;
  line-height: 24px;
}

p {
  font-style: normal;
  font-weight: 500;
  line-height: 150%;
}

a {
  color: inherit;
  text-decoration: none;
  cursor: pointer;
}

.full-screen {
  width: 100%;
  min-height: 100vh;
}

.container {
  max-width: 1200px;
  margin-left: auto;
  margin-right: auto;
}

.flex {
  display: flex;
}

.flex-justify {
  justify-content: space-between;
  align-items: center;
  display: flex;
  flex-wrap: wrap;
}

.block {
  display: block;
}

/* begin alert popup */


.toast {
  position: fixed;
  right: 0;
  top: 60px;
  margin: 30px;
  overflow: hidden;
  
  height: auto;
  width: 250px;
  padding: 17px;
  border-radius: 8px;
  box-shadow: 0 0 8px rgba(56, 56, 56, 0,);
  display: flex;
  justify-content: space-between;
  align-items: center;
  z-index: 999;
  }
  
  .toast  h2 {
    color: var(--font-white);
    font-weight: bold;
    font-size: 18px;
  }
  
  .toast p {
    color: var(--font-white);
    font-size: 14px;
  }

.pup-error {
    background-color: #FF0000;
  }

.pup-success {
  background-color: #00a000;
}

.info-container {
  color: #fff;
  display: flex;
  flex-direction: column;
  gap: 0px;
  padding-left: 80px;
  align-items: flex-start;
  justify-content: center;
  height: 100%;
}

.info-container .info-text {
  width: 57%;
  text-align: left;
  margin-bottom: 30px;
  font-size: 2.25rem;
}

.info-container .info-text-content {
  width: 55%;
  font-size: 1.6rem;
}

.info-container-right {
  float: right;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: center;
  color: #fff;
  padding-right: 80px;
}

.info-container-right .info-text {
  width: 70%;
  text-align: right;
  position: relative;
  margin-bottom: 30px;
  font-size: 2.25rem;
}

.info-container-right .info-text-content {
  width: 100%;
  font-size: 1.5rem;
  text-align: right;
  position: relative;
  max-width: 625px;
}

@media only screen and (max-width: 1400px) {
  .info-container .info-text,
  .info-container-right .info-text {
    font-size: 1.8rem;
    width: 100%;
    max-width: 690px;
    margin-bottom: 30px;
  }

  .info-container .info-text-content {
    width: 100%;
    max-width: 460px;
    font-size: 1.2rem;
  }

  .info-container-right .info-text-content {
    width: 100%;
    max-width: 500px;
    font-size: 1.2rem;
  }
}

@media only screen and (max-width: 1023px) {
  .info-container {
    /* margin-top: 15px; */
    width: 90%;
    margin-left: auto;
    margin-right: auto;
    align-items: center;
    padding-left: 0;
  }

  .info-container .info-text {
    right: auto;
    font-size: 1.6rem;
    text-align: center;
  }

  .info-container .info-text-content {
    right: auto;
    font-size: 1rem;
    text-align: center;
    color: #fff;
  }

  .info-container-right {
    /* margin-top: 15px; */
    width: 90%;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    align-items: center;
    gap: 0px;
    padding-right: 0;
    float: none;
  }

  .info-container-right .info-text {
    right: auto;
    font-size: 1.6rem;
    text-align: center;
  }

  .info-container-right .info-text-content {
    right: auto;
    font-size: 1rem;
    text-align: center;
    color: #fff;
  }
}

@media only screen and (max-width: 767px) {
  .info-container {
    align-items: center;
    justify-content: center;
    height: 100%;
  }

  .info-container .info-text {
    right: auto;
    font-size: 1.325rem;
    text-align: center;
  }

  .info-container .info-text-content {
    right: auto;
    font-size: 0.97rem;
    text-align: center;
  }

  .info-container-right {
    margin-top: 0px;
  }

  .info-container-right .info-text {
    right: auto;
    font-size: 1.325rem;
    text-align: center;
  }

  .info-container-right .info-text-content {
    right: auto;
    font-size: 0.97rem;
    text-align: center;
  }
}

/* end alert popup */
/* begin cookies */
.CookieMessage {
  position: fixed;
  width: 100%;
  height: 100%;
  z-index: 1000;
  display: none;
  align-items: end;
  justify-content: center;
}

.CookieMessage-content {
  display: flex;
  max-width: 100%;
  max-height: 180px;
  background-color: var(--color-primary);
  padding: 2rem;
  border-radius: 8px;
  color: var(--color-white-bg);
  margin-bottom: 20px;
  gap: 40px;
  align-items: center;
  height: 72px;
}

.CookieMessage-content a{
  color: white;
  font-weight: 700;
}

.CookieMessage-button {
  background: var(--color-secondary);
  display: flex;
  text-decoration: none;
  padding: 12px 14px;
  border-radius: 15px;
  text-transform: uppercase;
  justify-content: center;
  align-items: center;
  
}

.CookieMessage-button:hover{
  background: #db7c01;
}

.link-cookie {
  text-decoration: underline;
}
// /* end cookies */

.line-breaker {
  display: block;
  line-height: 20px;
  height: min-content;
}

@media screen and (max-width: 480px) {

  h1 {
    font-size: 3rem;
  }

}
@media screen and (max-width: 533px) {
  .CookieMessage-content {
    height: 128px;
  }
}

@media screen and (max-width: 374px) {
  .CookieMessage-content {
    height: 180px;
  }
}
